import React, { useEffect } from "react"
import './ValineComment.scss'

export default ({title}) => {

    // console.log("===window.location.pathname is")
    // console.log(window.location.pathname)


    useEffect(() => {
  
        const Valine = require("valine");
        new Valine().init({
            el: `#vcomments`,
            path: window.location.pathname,
            appId: 'o2kfEKPV0HsjkXcXqQbiEAHp-MdYXbMMI',
            appKey: 'nwrerESqIlNKDC1p9l2bxOa8',
            placeholder: 'Your comment ...',
            avatar: 'robohash',
          //   pageSize: 10,
            lang: 'en',
            visitor: true,
          //   recordIP: true,
          });
        
      },[])
    
      return (
        <div className="Comment-class">
          <span className="comment-title">{title}</span>
          <div className="vcomment" id="vcomments"></div>
        </div>
      )

  };
  


      